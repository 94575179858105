import React from 'react'

const Footer = () => {
  return (
    <div>
      <footer className="text-center text-white footer">
      <div className="container p-4 pb-0">
        <section className="mb-4">


          
          <a className="btn btn-outline-light btn-floating m-1" target="_blank" href="https://twitter.com/SkFacility?t=1i7WnF7sksvw5QS_3Tymjg&amp;s=08 " role="button"><i className="fab fa-twitter"></i></a>

          <a className="btn btn-outline-light btn-floating m-1" target="_blank" href="https://www.google.com/search?q=sk+facility+management+services+pvt+ltd&amp;oq=sk+facility+managementp&amp;gs_lcrp=EgZjaHJvbWUqCQgCEAAYDRiABDIGCAAQRRg5Mg8IARAuGA0YrwEYxwEYgAQyCQgCEAAYDRiABDIJCAMQABgNGIAEMggIBBAAGA0YHjINCAUQABiGAxiABBiKBTINCAYQABiGAxiABBiKBTINCAcQABiGAxiABBiKBTINCAgQABiGAxiABBiKBdIBCTEzODQ0ajBqN6gCCLACAQ&amp;sourceid=chrome&amp;ie=UTF-8&amp;lqi=CidzayBmYWNpbGl0eSBtYW5hZ2VtZW50IHNlcnZpY2VzIHB2dCBsdGRI5v6Zpei1gIAIWkEQABABEAIQAxAEEAUYABgBGAIYAxgEGAUiJ3NrIGZhY2lsaXR5IG1hbmFnZW1lbnQgc2VydmljZXMgcHZ0IGx0ZJIBCGNsZWFuZXJzqgFQEAEyHxABIhvZhIO9UxnEPSVHdHvI4t3SlAcoT-TU56aRwxQyKxACIidzayBmYWNpbGl0eSBtYW5hZ2VtZW50IHNlcnZpY2VzIHB2dCBsdGQ#ip=1&amp;rlimm=14923865549503286261" role="button"><i className="fab fa-google"></i></a>
  
          <a className="btn btn-outline-light btn-floating m-1" target="_blank" href="https://www.instagram.com/skfacilitymanagement/?hl=en" role="button"><i className="fab fa-instagram"></i></a>

          <a className="btn btn-outline-light btn-floating m-1" target="_blank" href="https://www.linkedin.com/company/sk-facility-management-services-private-limited/" role="button"><i className="fab fa-linkedin-in"></i></a>
        </section>
      </div>

      <ul style={{cursor: 'pointer'}}>
        <li style={{display: 'inline', marginRight: '1rem'}}><small>Term and Conditions</small></li>
        <li style={{display: 'inline'}}><small>Cookies Setting</small></li>
      </ul>
      <div className="text-center p-3" style={{backgroundColor:' rgba(0, 0, 0, 0.2)'}}>
        © Copyright <span id="curYr">2024</span> SKFM
      </div>
    </footer>
    </div>
  )
}

export default Footer
