import React from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { useEffect } from 'react';
const TENANT_ID = process.env.REACT_APP_AZURE_AUTHORITY

// Initialize MSAL instance (make sure to configure this according to your app's settings)
const msalInstance = new PublicClientApplication({
  auth: {
   clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
            authority: `https://login.microsoftonline.com/${TENANT_ID}`,
            redirectUri: `${process.env.REACT_APP_AZURE_REDIRECT_URI}`,
  },
  cache: {
    cacheLocation: "sessionStorage", // or "localStorage"
    storeAuthStateInCookie: false,
  }
});
const initializeMsal = async () => {
    await msalInstance.initialize();
};

const LogoutButton = () => {
  const handleLogout = async () => {
    try {
        await initializeMsal();

      await msalInstance.logoutRedirect();
      // Attempt to close the current window or tab
      window.close();
    } catch (e) {
    }
  };
  useEffect(() => {
    handleLogout();
}, []);
  return (
   null
  );
};



export default LogoutButton;
