import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import finallogo from "../../asset/finallogo.png";
import bgimage from "../../asset/Facility.jpg";
import { Modal, Button } from "react-bootstrap";
import check from "../../asset/check-mark-icon-png.png";
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import CryptoJS from 'crypto-js';
import Footer from "../../Footer/footer";



const CreateTraining = () => {
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [files, setFiles] = useState([[], [], []]);
  const [siteData, setSiteData] = useState([]);
  const [selectedSite, setSelectedSite] = useState("");
  const [location, setLocation] = useState("");
  const [trainerName, setTrainerName] = useState([]);
  const [selectedTrainerName, setSelectedTrainerName] = useState('');
  const [currentUser, setCurrentUser] = useState(null);
  const [topic, setTopic] = useState("");
  const [time, setTime] = useState("");
  const [show, setShow] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  // const [selectedAttachment, setSelectedAttachment] = useState(null);

  const [showInput, setShowInput] = useState(false);
  const [showSiteInput, setSiteShowInput] = useState(false);

  const SHAREPOINT_URI = process.env.REACT_APP_SharePoint_URI

  const navigate = useNavigate();
  const fetchRequestDigest = async () => {
    const token = decryptToken();

    if (!token) {
      alert("No authentication token found");
      return;
    }

    try {
      const response = await axios.post(`${SHAREPOINT_URI}/_api/contextinfo`, null, {
        headers: {
          Accept: "application/json;odata=verbose",
          "Content-Type": "application/json;odata=verbose",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.d.GetContextWebInformation.FormDigestValue;
    } catch (error) {
     
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      const token = decryptToken();
      const digestValue = await fetchRequestDigest();
      const endpoint = `${SHAREPOINT_URI}/_api/web/lists/getbytitle('${process.env.REACT_APP_SharePoint_List_1}')/items`;

      const item = {
        Date: date,
        SiteName: selectedSite,
        Location: location,
        TrainerName: selectedTrainerName,
        TrainingTopic: topic,
        Duration: `${time} Minutes`,
      };

      const headers = {
        Accept: "application/json;odata=verbose",
        "Content-Type": "application/json;odata=verbose",
        "X-RequestDigest": digestValue,
        Authorization: `Bearer ${token}`,
      };

      const metadata = {
        type: `SP.Data.${process.env.REACT_APP_SharePoint_List_1}ListItem`,
      };
      const data = { __metadata: metadata, ...item };

      const response = await axios.post(endpoint, JSON.stringify(data), { headers });

      const itemId = response.data.d.ID;
      const allFiles = files.flat();

      if (allFiles.length > 0) {
        await uploadFiles(allFiles, itemId, token, digestValue);
      }

      const matchingPPT = trainingPPT.find((ppt) => ppt.Title === topic);
      const pptBlob = matchingPPT ? await fetchFileBlob(matchingPPT.FileRef, token) : null;

      await sendEmailNotification({
        Title: item.TrainingTopic,
        Description: `Please find the attached PPT and Training Sheet for the Training conducted on your site.`,
        pptBlob,
        attachedFiles: allFiles,
      });

      setShow(true);
    } catch (error) {
    } finally {
      setIsSubmitting(false);
    }
  };
  const fetchFileBlob = async (fileUrl, token) => {
    try {
      const response = await fetch(fileUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch file: ${response.statusText}`);
      }

      const blob = await response.blob();
      return blob;
    } catch (error) {
      return null;
    }
  };
  const sendEmailNotification = async ({ Title, Description, pptBlob, attachedFiles }) => {
    const receiverEmail = process.env.REACT_APP_SharePoint_Email;

    const formData = new FormData();
    formData.append("to", receiverEmail);
    formData.append("subject", `New Training: ${Title}`);
    formData.append("body", `Dear client,\n\n${Description}`);

    if (pptBlob) {
      formData.append("attachment", pptBlob, "Training_PPT.pptx");
    } else {
      console.warn("No PPT blob available");
    }

    attachedFiles.forEach((file, index) => {
      formData.append(`attachment_${index}`, file, file.name);
    });

    try {
      const response = await axios.post("http://127.0.0.1:5000/send-email", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      alert("Email sent successfully");
    } catch (error) {


    }
  };
  const uploadFiles = async (files, itemId, token, digestValue) => {
    for (const file of files) {
      if (file && file instanceof File) {
        const endpoint = `${SHAREPOINT_URI}/_api/web/lists/getbytitle('${process.env.REACT_APP_SharePoint_List_1}')/items(${itemId})/AttachmentFiles/add(FileName='${file.name}')`;

        try {
          const fileArrayBuffer = await file.arrayBuffer();

          const headers = {
            Accept: "application/json;odata=verbose",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/octet-stream",
            "X-RequestDigest": digestValue,
          };

          await axios.post(endpoint, fileArrayBuffer, { headers });
        } catch (error) {
        }
      } else {
      }
    }
  };

  const fetchListData = async (url, accumulatedData = []) => {
    const token = decryptToken();
    if (!token) {
      return;
    }

    try {
      const response = await fetch(url, {
        headers: {
          Accept: 'application/json;odata=verbose',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const combinedData = [...accumulatedData, ...data.d.results];
      if (data.d.__next) {
        await fetchListData(data.d.__next, combinedData);
      } else {
        // Set state when all pages are fetched
        setSiteData(combinedData);
        setTrainerName(combinedData);
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    const initialUrl = `${process.env.REACT_APP_SharePoint_URI}/_api/web/lists/getbytitle('${process.env.REACT_APP_SharePoint_List_2}')/items?$top=100`;
    fetchListData(initialUrl);
  }, []);


  const handleSiteLocation = (event) => {
    const selectedSiteName = event.target.value; // Get the selected site name from the dropdown

    if (selectedSiteName === 'add-new') {
      setSiteShowInput(true); // Show the input field if "add-new" is selected
      setSelectedSite(''); // Reset selectedSite when showing input
    } else {
      setSiteShowInput(false); // Hide the input field when a valid site is selected
      setSelectedSite(selectedSiteName); // Set the selected site name
    }

    // Find the selected site data in siteData array based on the selected site name
    const selectedSiteData = siteData.find((site) => site.Title === selectedSiteName);
    if (selectedSiteData) {
      setLocation(selectedSiteData.field_1); // Set the location based on the selected site data
    }
  };
  const handleCustomSiteInputChange = (e) => {
    const newSite = e.target.value;
    setSelectedSite(newSite)

  }
  const handleSiteLabelClick = () => {
    setSiteShowInput(false); // Close the input field and show the dropdown
  };


  const handleTrainerName = (e) => {
    const value = e.target.value;
    setSelectedTrainerName(value); // Ensure this is set to the selected value

    if (value === 'add-new') {
      setShowInput(true);
      setSelectedTrainerName(''); // Reset selectedTrainerName when showing input
    } else {
      setShowInput(false);
      setSelectedTrainerName(value);
    }
  };
  const handleLabelClick = () => {
    setShowInput(false); // Close the input field and show the dropdown
  };

  const handleTrainerNameInputChange = (event) => {
    const newTrainerName = event.target.value;
    setSelectedTrainerName(newTrainerName);
  };

  const handleFileChange = (e, index) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length === 0) return;
  
    const maxImageSize = 0.3 * 1024 * 1024; // 0.3 MB for images
    const maxPdfSize = 2 * 1024 * 1024; // 2 MB for PDF files
    const validExtensions = /\.(jpg|jpeg|png|pdf|webp|PNG)$/i;
    const validFiles = [];
  
    selectedFiles.forEach((file) => {
      const isValidType = validExtensions.test(file.name);
      let isWithinSizeLimit = false;
  
      if (file.name.toLowerCase().endsWith(".pdf")) {
        isWithinSizeLimit = file.size <= maxPdfSize;
      } else {
        isWithinSizeLimit = file.size <= maxImageSize;
      }
  
      if (isValidType && isWithinSizeLimit) {
        const newName =
          index === 0
            ? `TrainingSheet${Date.now()}.${file.name.split('.').pop()}`
            : file.name;
        const renamedFile = new File([file], newName, { type: file.type });
        validFiles.push(renamedFile);
      } else {
        alert(
          `File ${file.name} is not supported or exceeds the size limit.`
        );
      }
    });
  
    if (validFiles.length === 0) {
      e.target.value = ""; // Clear input if no valid files are selected
      return;
    }
  
    const updatedFiles = [...files];
    updatedFiles[index] = validFiles;
    setFiles(updatedFiles);
  };
  

  const handleOk = () => {
    navigate("/dashboard");
  };
  const calculateDifference = () => {
    const start = new Date(`1970-01-01T${startTime}:00`);
    const end = new Date(`1970-01-01T${endTime}:00`);
    const diffInMinutes = `${end - start}`;
    const diffInMs = diffInMinutes / 60000

    if (diffInMs >= 10) {
      setTime(diffInMs);

    } else if (diffInMs <= 10) {
      alert('Training time must be 10 or Greater than Minutes ');
      setEndTime('')
      setTime("");

    }


  };
  useEffect(() => {
    calculateDifference();
  }, [startTime, endTime]);


  // shrpoint PPT document
  const [trainingPPT, setTrainingPPT] = useState([]);

  const fetchDocuments = async () => {
    const token = decryptToken();
    if (!token) {
      return;
    }
    try {
      const response = await fetch(

        `${SHAREPOINT_URI}/_api/web/lists/getbytitle('${process.env.REACT_APP_SharePoint_Doc_1}')/items?$select=Id,Title,FileRef,FileLeafRef&$filter=FSObjType eq 0`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json;odata=verbose',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setTrainingPPT(data.d.results);
    } catch (error) {
    }

  };
  const decryptToken = () => {
    const encryptedToken = localStorage.getItem('authToken');
    const encryptionKey = process.env.REACT_APP_key
    if (!encryptedToken || !encryptionKey) {
      return null; // Return null if token or key is missing
    }
    const bytes = CryptoJS.AES.decrypt(encryptedToken, encryptionKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  useEffect(() => {
    fetchDocuments();
  }, []);


  return (
    <>
      <div className="w3-top">
        <Navbar expand="lg" className="bg-body-tertiary">
          <Container fluid>
            <Navbar.Brand href="/newtraningRecord">
              <img src={finallogo} height='50' width='70' alt="logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">

              <Nav className="ms-auto my-2 my-lg-0" style={{ maxHeight: '' }} navbarScroll>
                <Nav.Link
                  className=' my-2 my-lg-0 ms-3'
                  href="/Logout"
                  style={{ color: '#fff', background: '#932c35', borderRadius: '5px' }}  // Add borderRadius here
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title={currentUser ? `${currentUser.Title} Want's To Logout!` : "Want To Logout!"}
                >
                  <i className="fa-solid fa-arrow-right-from-bracket fa-lg"></i> Logout
                </Nav.Link>
              </Nav>



            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <div
        className=""
        style={{
          backgroundImage: `url(${bgimage})`,
        }}
      >

        <div className="-r-22" style={{ height: '50px' }}><div className="-a-23"></div></div>
        <div className="container" style={{ backgroundColor: '#fff', maxWidth: '850px', overflow: 'auto', border: 'solid ', position: 'relative' }}>
          <div className="row mb-3" style={{ height: '9rem', backgroundColor: '#e5d9da' }}>
            <div style={{ padding: '1rem -0.25rem 3.125rem 3.125rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div>
                <img className="create-logo" alt="img" src={finallogo} height='70' width='70' style={{ marginTop: '50%' }} />
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                <h2 className="heading-main" style={{ color: '#ffffff', textAlign: 'center', margin: 0 }}>
                  SK Training
                </h2>
              </div>
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="" style={{ paddingLeft: "1.25rem" }}>
              <div
                className="col-md-11 form-group"
                style={{ padding: "2.1875rem 2.25rem" }}
              >
                <label
                  className="form-label detail-title" style={{ float: 'inline-start' }}
                >
                  1. Date:
                  <span className="tx-danger" style={{ color: "#dc3545" }}>
                    *
                  </span>
                </label>
                <input
                  type="date"
                  className="form-control "
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  id="MaintenanceDate"
                  name="MaintenanceDate"
                  required
                />
              </div>
              <div
                className="col-md-11 form-group"
                style={{ padding: "2.1875rem 2.25rem" }}
              >
                <label className="form-label detail-title" style={{ float: 'inline-start' }} onClick={handleSiteLabelClick}>
                  2. Select Site Name:{" "}
                  <span className="tx-danger" style={{ color: "#dc3545" }}>
                    *
                  </span>
                </label>
                {!showSiteInput ? (
                  <select
                    className="form-control"
                    value={selectedSite}
                    onChange={handleSiteLocation}
                    id="SiteName"
                    name="SiteName"
                    required
                  >
                    <option value="">--Select Site--</option>
                    {/* <option value="add-new" style={{ fontWeight: '700' }}>Other</option> */}
                    {siteData.map((site, index) => (
                      <option key={index} value={site.Title}>
                        {site.Title}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter New Site Name" // Updated placeholder text
                    onChange={handleCustomSiteInputChange}
                    required
                  />
                )}
              </div>

              <div
                className="col-md-11 form-group"
                style={{ padding: "2.1875rem 2.25rem" }}
              >
                <label className="form-label detail-title" style={{ float: 'inline-start' }}>
                  3. Location:{" "}
                  <span className="tx-danger" style={{ color: "#dc3545" }}>
                    *
                  </span>
                </label>
                <input
                  type="text"
                  className="form-control "
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  id="location"
                  placeholder="Location"

                  required
                />
              </div>

              <div
                className="col-md-11 form-group"
                style={{ padding: "2.1875rem 2.25rem" }}
              >
                <label className="form-label detail-title" style={{ float: 'inline-start' }} onClick={handleLabelClick}>
                  4. Trainer Name:
                  <span className="tx-danger" style={{ color: "#dc3545" }}>
                    *
                  </span>

                </label>
                {!showInput ? (
                  <select
                    type="text"
                    className="form-control"
                    onChange={handleTrainerName}
                    placeholder="Enter Trainer Name"
                    required
                  >
                    <option value="">--Select Trainer Name--</option>
                    <option value="add-new" style={{ fontWeight: '700' }} >Other</option>

                    {trainerName
                      .filter((site) => site.field_2 && site.field_2.trim() !== '')
                      .map((site, Id) => (
                        <option key={Id} value={site.field_2}>
                          {site.field_2}
                        </option>
                      ))}
                  </select>
                ) : (
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter New Trainer Name"
                    onChange={handleTrainerNameInputChange}
                    required
                  />
                )}

              </div>
              <div
                className="col-md-11 form-group"
                style={{ padding: "2.1875rem 2.25rem" }}
              >
                <label className="form-label detail-title" style={{ float: 'inline-start' }}>
                  5. Training Topic:
                  <span className="tx-danger" style={{ color: "#dc3545" }}>
                    *
                  </span>
                </label>
                <select
                  type="text"
                  className="form-control "
                  onChange={(e) => setTopic(e.target.value)}
                  placeholder="Enter Training Topic"
                  required
                >
                  <option value="">Select Training</option>
                  {trainingPPT.map((doc) => (
                    <option key={doc.FileRef} value={doc.Title}>
                      {doc.FileLeafRef}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-11 form-group" style={{ padding: "2.1875rem 2.25rem" }}>
                <label className="form-label detail-title" style={{ float: 'inline-start' }}>
                  6. Training Duration:{" "}
                  <span className="tx-danger" style={{ color: "#dc3545" }}>
                    *
                  </span>
                </label>


                <div className="col-md-5 form-group">

                  <label className="form-label detail-title" style={{ float: 'inline-start' }}>Start Time</label>

                  <input
                    type="time"
                    value={startTime}
                    className="form-control col-md-4"
                    onChange={(e) => setStartTime(e.target.value)}
                    
                  />

                  <label className="form-label detail-title" style={{ float: 'inline-start' }}>End Time</label>

                  <input
                    type="time"
                    value={endTime}
                    className="form-control col-md-4 "
                    onChange={(e) => setEndTime(e.target.value)}
                    
                  />
                  <label className="form-label detail-title" style={{ float: 'inline-start' }}>Total Duration</label>

                  <input
                    type="text"
                    value={`${time} Minutes`}
                    className="form-control mt-3"
                    onChange={(e) => setTime(e.target.value)}
                    readOnly
                    
                  >

                  </input>
                </div>
              </div>
              <div
                className="col-md-11 form-group"
                style={{ padding: "2.1875rem 2.25rem" }}>
                <label className="form-label detail-title" style={{ float: 'inline-start' }}>7. Training Sheet <span className="tx-danger" style={{ color: "#dc3545" }}>
                  <small style={{ fontSize: '0.8rem' }}>(Max size: 300KB IMG or 2MB pdf)</small>
                </span></label>

                <input
                  type="file"
                  multiple
                  accept="image/*,.pdf"
                  className="form-control"
                  onChange={(e) => handleFileChange(e, 0)}
                  
                />
              </div>

              <div
                className="col-md-11 form-group"
                style={{ padding: "2.1875rem 2.25rem" }}>
                <label className="form-label detail-title" style={{ float: 'inline-start' }}>9. Training Pictures <span className="tx-danger" style={{ color: "#dc3545" }}>
                  <small style={{ fontSize: '0.8rem' }}>(Max size: 300KB/IMG)</small>
                </span></label>

                <input
                  type="file"
                  multiple
                  accept="image/*"
                  className="form-control"
                  onChange={(e) => handleFileChange(e, 1)}
                  
                />
              </div>

              <div
                className="text-center"
                style={{ padding: "2.1875rem 2.25rem" }}
              >
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isSubmitting}
                  style={{
                    borderRadius: "25px",
                    backgroundColor: isSubmitting ? "#ccc" : "#932c35",
                    width: "150px",
                    cursor: isSubmitting ? "not-allowed" : "pointer",
                  }}
                >
                  {isSubmitting ? "Submitting..." : "Create"}
                </button>

              </div>
            </div>
          </form>
          <Footer/>
          <Modal show={show}>
            <Modal.Header>
              <Modal.Title>Success</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="text-center">
                <img
                  src={check}
                  alt="Success"
                  style={{ height: "100px", marginBottom: "20px" }}
                />
                <p>Training Record successfully!</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleOk}>
                OK
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default CreateTraining;

