import React, { useState, useEffect } from "react";
import finallogo from "../asset/finallogo.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import gif from "..//asset/search.gif";
import Footer from "../Footer/footer";
import Nav_menu from "./Header/navbar";
import { Modal } from "react-bootstrap";

const Dashboard = () => {
  const navigate = useNavigate();
  const SHAREPOINT_URI = process.env.REACT_APP_SharePoint_URI;
  const [displayData, setDisplayData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [attachments, setAttachments] = useState({});
  const [userEmail, setUserEmail] = useState("");
  const [currentUser, setCurrentUser] = useState(null);
  // const [trainingPPT, setTrainingPPT] = useState([]);
  const [show, setShow] = useState(false);

  // ************ Helper Function for Token Decryption ********************//

  // Helper function to decrypt token
  const decryptToken = () => {
    const encryptedToken = localStorage.getItem("authToken");
    const encryptionKey = process.env.REACT_APP_key;

    if (!localStorage.getItem("authToken") || !process.env.REACT_APP_key) {
      navigate("/"); // Navigate to Auth if token or key is missing
      return null;
    }
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedToken, encryptionKey);
      return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      navigate("/"); // Navigate to Auth if decryption fails
      return null;
    }
  };
  // ************ Fetch User Data ********************//
  const fetchUserData = async () => {
    const token = decryptToken();
    if (!token) {
      return;
    }

    try {
      const response = await fetch(`${SHAREPOINT_URI}/_api/web/currentuser`, {
        headers: {
          Accept: "application/json;odata=verbose",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(
          `Error fetching user data: ${response.status} - ${response.statusText}`
        );
      }

      const userData = await response.json();
      setUserEmail(userData.d.Email);
      setCurrentUser(userData.d); // Store user data

      fetchListData(token, userData.d.Email);
    } catch (error) {
    }
  };

  // ************ Fetch List Data ********************//
  const fetchListData = async (token, userEmail) => {
    try {
      const response = await fetch(
        `${SHAREPOINT_URI}/_api/web/lists/getbytitle('${process.env.REACT_APP_SharePoint_List_1}')/items`,
        {
          headers: {
            Accept: "application/json;odata=verbose",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setDisplayData(data.d.results);

      data.d.results.forEach((item) => {
        fetchexcel(item.Id, token);
      });
    } catch (error) {
    }
  };

  // ************ Fetch Excel Data ********************//
  const fetchexcel = async (itemId, token) => {
    try {
      const response = await fetch(
        `${SHAREPOINT_URI}/_api/web/lists/getbytitle('${process.env.REACT_APP_SharePoint_List_1}')/items(${itemId})/AttachmentFiles`,
        {
          headers: {
            Accept: "application/json;odata=verbose",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(
          `Error fetching excel for item ${itemId}: ${response.status}`
        );
      }

      const data = await response.json();
      setAttachments((prevAttachments) => ({
        ...prevAttachments,
        [itemId]: data.d.results,
      }));
    } catch (error) {
    }
  };

  // ************ useEffect Hooks ********************//
  useEffect(() => {
    fetchUserData();

  }, []);

  return (
    <>


      <Nav_menu style={{}} />


      {/*******************table****** */}
      <section className="intro">
        <div className="mask d-flex align-items-center h-100">
          <div className="container">
            <div className="title">
              <h2>Training Detail</h2>
            </div>
            <div className="row justify-content-center">
              <div className="col-12">
                <div
                  className="card shadow-2-strong"
                  style={{ backgroundColor: "#f5f7fa" }}
                >
                  <div className="card-body">
                    {/* Display current user data */}

                    <div
                      className="table-responsive"
                      style={{ height: "20rem" }}
                    >
                      <table className="table table-fixed mb-0" style={{}}>
                      <thead>
  <tr style={{ borderBottom: "1px solid", position: "sticky", top: 0, zIndex: 2, backgroundColor: "#f8f9fa" }}>
    <th scope="col" className="table-column-width1"> Date </th>
    <th scope="col" className="table-column-width1">
      Site Name
      <i
        className="fa-solid fa-angle-down"
        style={{ cursor: "pointer" }}
        onClick={() => setShow(true)}
      ></i>
      {show && (
        <div className="filter_box">
          <Form className="d-flex">
            <Form.Control
              className="form-control mr-sm-2"
              type="search"
              placeholder="Enter SiteName"
              aria-label="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <i
              className="fa-solid fa-circle-xmark"
              style={{
                color: "#8e2c1a",
                cursor: "pointer",
                fontSize: "2rem",
                marginLeft: "1%",
                marginTop: "1%",
              }}
              onClick={() => setShow(false)}
            ></i>
          </Form>
        </div>
      )}
    </th>
    <th scope="col" className="table-column-width1"> Location </th>
    <th scope="col" className="table-column-width1"> TrainerName </th>
    <th scope="col" className="table-column-width1"> TrainingTopic </th>
    <th scope="col" className="table-column-width1"> Duration </th>
    <th scope="col" className="table-column-width1"> Attachments </th>
  </tr>
</thead>

                        <tbody>
                          {displayData && displayData.length > 0 ? (
                            displayData.filter(
                              (training) =>
                                currentUser &&
                                training.AuthorId === currentUser.Id &&
                                training.SiteName &&
                                training.SiteName.toString()
                                  .toLowerCase()
                                  .includes(searchQuery.toLowerCase())
                            ).length > 0 ? (
                              displayData
                                .filter(
                                  (training) =>
                                    currentUser &&
                                    training.AuthorId === currentUser.Id &&
                                    training.SiteName &&
                                    training.SiteName.toString()
                                      .toLowerCase()
                                      .includes(searchQuery.toLowerCase())
                                )
                                .map((training) => (
                                  <tr
                                    key={training.Id}
                                    style={{ marginBottom: "1rem" }}
                                  >
                                    <td className="table-column-width">
                                      {training.Date}
                                    </td>
                                    <td className="table-column-width">
                                      {training.SiteName}
                                    </td>
                                    <td className="table-column-width">
                                      {training.Location}
                                    </td>
                                    <td className="table-column-width">
                                      {training.TrainerName}
                                    </td>
                                    <td className="table-column-width">
                                      {training.TrainingTopic}
                                    </td>
                                    <td className="table-column-width">
                                      {training.Duration}
                                    </td>
                                    <td className="table-column-width">
                                      {attachments[training.Id] &&
                                        attachments[training.Id].length > 0 ? (
                                        <ul>
                                          {attachments[training.Id].map(
                                            (attachment, index) => {
                                              const fileExtension =
                                                attachment.FileName.split(".")
                                                  .pop()
                                                  .toLowerCase();
                                              const isOfficeDocument = [
                                                "docx",
                                                "xlsx",
                                                "pptx",
                                                "xls",
                                                "doc",
                                              ].includes(fileExtension);

                                              return (
                                                <li
                                                  key={attachment.FileName}
                                                  style={{
                                                    border: "1px solid",
                                                    marginBottom: "0.2rem",
                                                  }}
                                                >
                                                  {isOfficeDocument ? (
                                                    <a
                                                      className="cstm-links"
                                                      href={`${process.env.REACT_APP_SharePoint_URI}/_layouts/15/WopiFrame.aspx?sourcedoc=${attachment.ServerRelativeUrl}&action=view`}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      style={{
                                                        borderRadius: "0.5rem",
                                                      }}
                                                    >
                                                      {attachment.FileName}
                                                    </a>
                                                  ) : (
                                                    <a
                                                      className="cstm-links"
                                                      href={`${process.env.REACT_APP_SharePoint_URI}${attachment.ServerRelativeUrl}`}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      style={{
                                                        borderRadius: "0.5rem",
                                                      }}
                                                    >
                                                      {attachment.FileName}
                                                    </a>
                                                  )}
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      ) : (
                                        "No attachments"
                                      )}
                                    </td>
                                  </tr>
                                ))
                            ) : (
                              <tr>
                                <td colSpan="8" style={{ textAlign: "center" }}>
                                  <img src={gif}></img> No detail found
                                </td>
                              </tr>
                            )
                          ) : (
                            <tr>
                              <td colSpan="8" style={{ textAlign: "center" }}>
                                <img src={gif}></img> No detail found
                                <br />
                                <Link to="/newtraningRecord">
                                  Please Create New Training
                                </Link>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Modal  show={show}>
            
           
            <Form className="d-flex ">
              <Form.Control
                className="form-control mr-sm-2"
                type="search"
                placeholder="Enter SiteName"
                aria-label="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <i class="fa-solid fa-circle-xmark" style={{color:'#8e2c1a',}}  onClick={() => setShow(false)}></i>

            </Form>
            
          </Modal> */}
      {/* table end */}
      <Footer />
    </>
  );
};

export default Dashboard;

