import React, { useState, useEffect } from "react";
import finallogo from "..//..//asset/finallogo.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Form from "react-bootstrap/Form";
import { Link,useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
const Nav_menu = () => {
    const navigate = useNavigate();

  const SHAREPOINT_URI = process.env.REACT_APP_SharePoint_URI;
  const [userEmail, setUserEmail] = useState('');
  const [displayData, setDisplayData] = useState([]);
  const [houseKeeping_Ppts, setHouseKeeping_Ppts] = useState([]);
  const [pantry_Ppts, setPantry_Ppts] = useState([]);
  const [technical_Ppts, setTechnical_Ppts] = useState([]);
  const [common_Ppts, setCommon_Ppts] = useState([]);
  const [machine_uses, setMachine_uses] = useState([]);
  const [jd_job_Card, setJd_job_Card] = useState([]);
  const [hK_SOP_PMS, setHK_SOP_PMS] = useState([]);
  const [check_List, setCheck_List] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentUser, setCurrentUser] = useState(null);
  const [other, setOther] = useState([]);
  


  const decryptToken = () => {
    const encryptedToken = localStorage.getItem("authToken");
    const encryptionKey = process.env.REACT_APP_key;

    if (!encryptedToken || !encryptionKey) {
      navigate("/"); // Navigate to Auth if token or key is missing
      return null;
    }

    try {
      const bytes = CryptoJS.AES.decrypt(encryptedToken, encryptionKey);
      return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      navigate("/"); // Navigate to Auth if decryption fails
      return null;
    }
  };
  const fetchUserData = async () => {
    const token = decryptToken();
    if (!token) {
        return;
    }

    try {
        const response = await fetch(
            `${SHAREPOINT_URI}/_api/web/currentuser`,
            {
                headers: {
                    Accept: 'application/json;odata=verbose',
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        if (!response.ok) {
            throw new Error(`Error fetching user data: ${response.status} - ${response.statusText}`);
        }

        const userData = await response.json();
        setUserEmail(userData.d.Email);
        setCurrentUser(userData.d); // Store user data

        fetchListData(token, userData.d.Email);
    } catch (error) {
    }
};
   // Filter the data based on search query and currentUser
   const filteredData = displayData.filter(
    (training) =>
      training.AuthorId === currentUser.Id &&
      training.SiteName &&
      training.SiteName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const fetchListData = async (token, userEmail) => {
    
    try {
        const response = await fetch(
            `${SHAREPOINT_URI}/_api/web/lists/getbytitle('${process.env.REACT_APP_SharePoint_List_1}')/items`,
            {
                headers: {
                    Accept: 'application/json;odata=verbose',
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setDisplayData(data.d.results);

       
    } catch (error) {
    }
};


  
  // ************ Navbar_start********************//

  // ************ Fetch Housekeeping PPTs ********************//
  const fetchHouseKeeping_Ppts = async () => {
    const token = decryptToken();
    if (!token) {
      return;
    }

    try {
      const response = await fetch(
        `${SHAREPOINT_URI}/_api/web/lists/getbytitle('${process.env.REACT_APP_SharePoint_Doc_1}')/items?$select=Id,Title,FileRef,FileLeafRef&$filter=FSObjType eq 0 and substringof('${process.env.REACT_APP_SharePoint_Doc_2}', FileRef)`,
        {
          method: "GET",
          headers: {
            Accept: "application/json;odata=verbose",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(
          `Error fetching documents: ${response.status} - ${response.statusText}`
        );
      }

      const data = await response.json();
      setHouseKeeping_Ppts(data.d.results);
    } catch (error) {
    }
  };
  // ****************pantryBoy***************************//
  const fetchPantryBoy_Ppts = async () => {
    const token = decryptToken();
    if (!token) {
      return;
    }

    try {
      const response = await fetch(
        `${SHAREPOINT_URI}/_api/web/lists/getbytitle('${process.env.REACT_APP_SharePoint_Doc_1}')/items?$select=Id,Title,FileRef,FileLeafRef&$filter=FSObjType eq 0 and substringof('${process.env.REACT_APP_SharePoint_Doc_3}', FileRef)`,
        {
          method: "GET",
          headers: {
            Accept: "application/json;odata=verbose",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(
          `Error fetching documents: ${response.status} - ${response.statusText}`
        );
      }

      const data = await response.json();
      setPantry_Ppts(data.d.results);
    } catch (error) {
    }
  };
  // ******************technical*************************//
  const fetchTechnical_Ppts = async () => {
    const token = decryptToken();
    if (!token) {
      return;
    }

    try {
      const response = await fetch(
        `${SHAREPOINT_URI}/_api/web/lists/getbytitle('${process.env.REACT_APP_SharePoint_Doc_1}')/items?$select=Id,Title,FileRef,FileLeafRef&$filter=FSObjType eq 0 and substringof('${process.env.REACT_APP_SharePoint_Doc_4}', FileRef)`,
        {
          method: "GET",
          headers: {
            Accept: "application/json;odata=verbose",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(
          `Error fetching documents: ${response.status} - ${response.statusText}`
        );
      }

      const data = await response.json();
      setTechnical_Ppts(data.d.results);
    } catch (error) {
    }
  };
  // *********************Common**********************//
  const fetchCommon_Ppts = async () => {
    const token = decryptToken();
    if (!token) {
      return;
    }

    try {
      const response = await fetch(
        `${SHAREPOINT_URI}/_api/web/lists/getbytitle('${process.env.REACT_APP_SharePoint_Doc_1}')/items?$select=Id,Title,FileRef,FileLeafRef&$filter=FSObjType eq 0 and substringof('${process.env.REACT_APP_SharePoint_Doc_5}', FileRef)`,
        {
          method: "GET",
          headers: {
            Accept: "application/json;odata=verbose",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(
          `Error fetching documents: ${response.status} - ${response.statusText}`
        );
      }

      const data = await response.json();
      setCommon_Ppts(data.d.results);
    } catch (error) {
    }
  };
  // **********************Machine uses********************************//
  const fetchHK_Machine_uses = async () => {
    const token = decryptToken();
    if (!token) {
      return;
    }

    try {
      const response = await fetch(
        `${SHAREPOINT_URI}/_api/web/lists/getbytitle('${process.env.REACT_APP_SharePoint_Doc_6}')/items?$select=Id,Title,FileRef,FileLeafRef&$filter=FSObjType eq 0 and substringof('${process.env.REACT_APP_SharePoint_Doc_7}', FileRef)`,
        {
          method: "GET",
          headers: {
            Accept: "application/json;odata=verbose",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(
          `Error fetching documents: ${response.status} - ${response.statusText}`
        );
      }

      const data = await response.json();
      setMachine_uses(data.d.results);
    } catch (error) {
    }
  };
  // *************************fetchHK_setJd_job_Card*****************************//
  const fetchHK_setJd_job_Card = async () => {
    const token = decryptToken();
    if (!token) {
      return;
    }

    try {
      const response = await fetch(
        `${SHAREPOINT_URI}/_api/web/lists/getbytitle('${process.env.REACT_APP_SharePoint_Doc_6}')/items?$select=Id,Title,FileRef,FileLeafRef&$filter=FSObjType eq 0 and substringof('${process.env.REACT_APP_SharePoint_Doc_8}', FileRef)`,
        {
          method: "GET",
          headers: {
            Accept: "application/json;odata=verbose",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(
          `Error fetching documents: ${response.status} - ${response.statusText}`
        );
      }

      const data = await response.json();
      setJd_job_Card(data.d.results);
    } catch (error) {
    }
  };
  // **************************fetchHK_hK_SOP_PMS**************************************//
  const fetchHK_hK_SOP_PMS = async () => {
    const token = decryptToken();
    if (!token) {
      return;
    }

    try {
      const response = await fetch(
        `${SHAREPOINT_URI}/_api/web/lists/getbytitle('${process.env.REACT_APP_SharePoint_Doc_6}')/items?$select=Id,Title,FileRef,FileLeafRef&$filter=FSObjType eq 0 and substringof('${process.env.REACT_APP_SharePoint_Doc_9}', FileRef)`,
        {
          method: "GET",
          headers: {
            Accept: "application/json;odata=verbose",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(
          `Error fetching documents: ${response.status} - ${response.statusText}`
        );
      }

      const data = await response.json();
      setHK_SOP_PMS(data.d.results);
    } catch (error) {
    }
  };

  // **************************fetchHK_HK_Check_List**************************************//
  const fetchHK_HK_Check_List = async () => {
    const token = decryptToken();
    if (!token) {
      return;
    }

    try {
      const response = await fetch(
        `${SHAREPOINT_URI}/_api/web/lists/getbytitle('${process.env.REACT_APP_SharePoint_Doc_6}')/items?$select=Id,Title,FileRef,FileLeafRef&$filter=FSObjType eq 0 and substringof('${process.env.REACT_APP_SharePoint_Doc_10}', FileRef)`,
        {
          method: "GET",
          headers: {
            Accept: "application/json;odata=verbose",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(
          `Error fetching documents: ${response.status} - ${response.statusText}`
        );
      }

      const data = await response.json();
      setCheck_List(data.d.results);
    } catch (error) {
    }
  }; // **************************mislenious**************************************//
  const fetch_Other = async () => {
    const token = decryptToken();
    if (!token) {
      return;
    }

    try {
      const response = await fetch(
        `${SHAREPOINT_URI}/_api/web/lists/getbytitle('${process.env.REACT_APP_SharePoint_Doc_6}')/items?$select=Id,Title,FileRef,FileLeafRef&$filter=FSObjType eq 0 and substringof('${process.env.REACT_APP_SharePoint_Doc_11}', FileRef)`,
        {
          method: "GET",
          headers: {
            Accept: "application/json;odata=verbose",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(
          `Error fetching documents: ${response.status} - ${response.statusText}`
        );
      }

      const data = await response.json();
      setOther(data.d.results);
    } catch (error) {
    }
  };

  // ******************************Navbar end**********************************//
  // ************ useEffect Hooks ********************//
  useEffect(() => {
    fetchUserData();
    fetchHouseKeeping_Ppts();
    fetchPantryBoy_Ppts();
    fetchTechnical_Ppts();
    fetchCommon_Ppts();
    fetchHK_Machine_uses();
    fetchHK_setJd_job_Card();
    fetchHK_hK_SOP_PMS();
    fetchHK_HK_Check_List();
    fetch_Other();

}, []);

  return (
    <>
      <div className="w3-top" style={{position:'relative',zIndex:'10'}}>
      <Navbar expand="lg" className="">
        <Container fluid>
          <Navbar.Brand href="/newtraningRecord">
            <img src={finallogo} height="50" width="70" alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse
            id="navbarScroll"
            className="bar-nav"
            style={{ gap: "100px", justifyContent: "end" }}
          >
           

            <Nav className=" my-2 my-lg-0">
              <span
                className="nav-link mega-menu-toggle-sub"
                style={{
                  borderRadius: "0.5rem",
                  background: "#932c35",
                  color: "#fff",
                }}
              >
                PPTs
              </span>
              <ul className="mega-menu-sub mega-menu-toggle" style={{}}>
                {/*************************  HK PPT *****************/}
                {/* <Nav className="me-auto my-2 my-lg-0"> */}
                <span
                  className="nav-link mega-menu-toggle"
                  style={{
                    borderRadius: "0.5rem",
                    background: "#932c35",
                    color: "#fff",
                    paddingLeft: "10px",
                  }}
                >
                  House Keeping
                </span>
                <ul className="mega-menu">
                  {houseKeeping_Ppts.map((powerPoint) => {
                    // Constructing the URL with more parameters to hide SharePoint UI elements
                    const wopiFrameUrl = `${SHAREPOINT_URI}/_layouts/15/WopiFrame.aspx?sourcedoc=${encodeURIComponent(
                      powerPoint.FileRef
                    )}&action=embedview&hidetoolbar=true&ui=embed`;
                    return (
                      <li key={powerPoint.FileRef} className="ppt-grid">
                        <span>
                          {" "}
                          <a
                            className="cstm-links ms-3 "
                            href={wopiFrameUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {powerPoint.FileLeafRef}
                          </a>
                        </span>
                      </li>
                    );
                  })}
                </ul>
                {/* </Nav> */}
                {/*************************  HK PB *****************/}
                {/* <Nav className="me-auto my-2 my-lg-0"> */}
                <span
                  className="nav-link mega-menu-toggle"
                  style={{
                    borderRadius: "0.5rem",
                    background: "#932c35",
                    color: "#fff",
                    paddingLeft: "10px",
                  }}
                >
                  Pantry boy
                </span>
                <li className="mega-menu ">
                  {pantry_Ppts.map((powerPoint) => {
                    // Constructing the URL with more parameters to hide SharePoint UI elements
                    const wopiFrameUrl = `${SHAREPOINT_URI}/_layouts/15/WopiFrame.aspx?sourcedoc=${encodeURIComponent(
                      powerPoint.FileRef
                    )}&action=embedview&hidetoolbar=true&ui=embed`;
                    return (
                      <div key={powerPoint.FileRef} className="ppt-grid">
                        <a
                          className="cstm-links ms-3 "
                          href={wopiFrameUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {powerPoint.FileLeafRef}
                        </a>
                      </div>
                    );
                  })}
                </li>
                {/* </Nav> */}
                {/*************************   technical *****************/}
                {/* <Nav className="me-auto my-2 my-lg-0"> */}
                <span
                  className="nav-link mega-menu-toggle"
                  style={{
                    borderRadius: "0.5rem",
                    background: "#932c35",
                    color: "#fff",
                    paddingLeft: "10px",
                  }}
                >
                  Technical(M&E)
                </span>
                <li className="mega-menu ">
                  {technical_Ppts.map((powerPoint) => {
                    // Constructing the URL with more parameters to hide SharePoint UI elements
                    const wopiFrameUrl = `${SHAREPOINT_URI}/_layouts/15/WopiFrame.aspx?sourcedoc=${encodeURIComponent(
                      powerPoint.FileRef
                    )}&action=embedview&hidetoolbar=true&ui=embed`;
                    return (
                      <div key={powerPoint.FileRef} className="ppt-grid">
                        <a
                          className="cstm-links ms-3 "
                          href={wopiFrameUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {powerPoint.FileLeafRef}
                        </a>
                      </div>
                    );
                  })}
                </li>
                {/* </Nav> */}
                {/*************************  HK comman *****************/}
                {/* <Nav className="me-auto my-2 my-lg-0"> */}
                <span
                  className="nav-link mega-menu-toggle"
                  style={{
                    borderRadius: "0.5rem",
                    background: "#932c35",
                    color: "#fff",
                    paddingLeft: "10px",
                  }}
                >
                  Common
                </span>
                <li className="mega-menu ">
                  {common_Ppts.map((powerPoint) => {
                    // Constructing the URL with more parameters to hide SharePoint UI elements
                    const wopiFrameUrl = `${SHAREPOINT_URI}/_layouts/15/WopiFrame.aspx?sourcedoc=${encodeURIComponent(
                      powerPoint.FileRef
                    )}&action=embedview&hidetoolbar=true&ui=embed`;
                    return (
                      <div key={powerPoint.FileRef} className="ppt-grid">
                        <a
                          className="cstm-links ms-3 "
                          href={wopiFrameUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {powerPoint.FileLeafRef}
                        </a>
                      </div>
                    );
                  })}
                </li>
                {/* </Nav> */}
              </ul>
            </Nav>
            <Nav className=" my-2 my-lg-0">
              <div
                className="nav-link mega-menu-toggle-sub"
                style={{
                  borderRadius: "0.5rem",
                  background: "#932c35",
                  color: "#fff",
                }}
              >
                HK Essentials
              </div>
              <div className="mega-menu-sub ">
                {/*************************  HK PPT *****************/}
                {/* <Nav className="me-auto my-2 my-lg-0"> */}
                <div
                  className="nav-link mega-menu-toggle"
                  style={{
                    borderRadius: "0.5rem",
                    background: "#932c35",
                    color: "#fff",
                  }}
                >
                  Machine Uses
                </div>

                <div className="mega-menu">
                  {machine_uses.map((machine_usess) => {
                    // Extract file extension to check the file type
                    const fileExtension = machine_usess.FileLeafRef.split(".")
                      .pop()
                      .toLowerCase();
                    let machines_usesUrl;

                    // Use WopiFrame for non-PDF files, direct URL for PDF
                    if (fileExtension === "pdf") {
                      machines_usesUrl = `${SHAREPOINT_URI}/${encodeURIComponent(
                        machine_usess.FileRef
                      )}`;
                    } else {
                      machines_usesUrl = `${SHAREPOINT_URI}/_layouts/15/WopiFrame.aspx?sourcedoc=${encodeURIComponent(
                        machine_usess.FileRef
                      )}&action=embedview&hidetoolbar=true&ui=embed&wdHideToolbar=true`;
                    }

                    return (
                      <div key={machine_usess.FileRef} className="ppt-grid">
                        <a
                          className="cstm-links ms-3"
                          href={machines_usesUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {machine_usess.FileLeafRef}
                        </a>
                      </div>
                    );
                  })}
                </div>

                {/*************************  JD *****************/}

                <div
                  className="nav-link mega-menu-toggle"
                  style={{
                    borderRadius: "0.5rem",
                    background: "#932c35",
                    color: "#fff",
                  }}
                >
                  JD/Job Card
                </div>
                <div className="mega-menu">
                  {jd_job_Card.map((jd_job_Cards) => {
                    // Extract file extension to check the file type
                    const fileExtension = jd_job_Cards.FileLeafRef.split(".")
                      .pop()
                      .toLowerCase();
                    let jd_job_CardUrl;

                    // Use WopiFrame for non-PDF files, direct URL for PDF
                    if (fileExtension === "pdf") {
                      jd_job_CardUrl = `${SHAREPOINT_URI}/${encodeURIComponent(
                        jd_job_Cards.FileRef
                      )}`;
                    } else {
                      jd_job_CardUrl = `${SHAREPOINT_URI}/_layouts/15/WopiFrame.aspx?sourcedoc=${encodeURIComponent(
                        jd_job_Cards.FileRef
                      )}&action=embedview&hidetoolbar=true&ui=embed&wdHideToolbar=true`;
                    }

                    return (
                      <div key={jd_job_Cards.FileRef} className="ppt-grid">
                        <a
                          className="cstm-links ms-3"
                          href={jd_job_CardUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {jd_job_Cards.FileLeafRef}
                        </a>
                      </div>
                    );
                  })}
                </div>

                <div
                  className="nav-link mega-menu-toggle"
                  style={{
                    borderRadius: "0.5rem",
                    background: "#932c35",
                    color: "#fff",
                  }}
                >
                  HK-SOP-PMS{" "}
                </div>
                <div className="mega-menu">
                  {hK_SOP_PMS.map((hK_SOP_PMS) => {
                    // Construct the URL with the necessary parameters for hiding SharePoint's UI
                    const fileExtension = hK_SOP_PMS.FileLeafRef.split(".")
                      .pop()
                      .toLowerCase();
                    let hK_SOP_PMSUrl;
                    if (fileExtension === "pdf") {
                      hK_SOP_PMSUrl = `${SHAREPOINT_URI}/${encodeURIComponent(
                        hK_SOP_PMS.FileRef
                      )}`;
                    } else {
                      hK_SOP_PMSUrl = `${SHAREPOINT_URI}/_layouts/15/WopiFrame.aspx?sourcedoc=${encodeURIComponent(
                        hK_SOP_PMS.FileRef
                      )}&action=embedview&hidetoolbar=true&ui=embed&wdHideToolbar=true`;
                    }

                    return (
                      <div key={hK_SOP_PMS.FileRef} className="ppt-grid">
                        <a
                          className="cstm-links ms-3"
                          href={hK_SOP_PMSUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {hK_SOP_PMS.FileLeafRef}
                        </a>
                      </div>
                    );
                  })}
                </div>
                <div
                  className="nav-link mega-menu-toggle"
                  style={{
                    borderRadius: "0.5rem",
                    background: "#932c35",
                    color: "#fff",
                  }}
                >
                  Check List{" "}
                </div>
                <div className="mega-menu">
                  {check_List.map((check_List) => {
                    // Construct the URL with the necessary parameters for hiding SharePoint's UI
                    const fileExtension = check_List.FileLeafRef.split(".")
                      .pop()
                      .toLowerCase();
                    let otherUrl;
                    if (fileExtension === "pdf") {
                      otherUrl = `${SHAREPOINT_URI}/${encodeURIComponent(
                        check_List.FileRef
                      )}`;
                    } else {
                      otherUrl = `${SHAREPOINT_URI}/_layouts/15/WopiFrame.aspx?sourcedoc=${encodeURIComponent(
                        check_List.FileRef
                      )}&action=embedview&hidetoolbar=true&ui=embed&wdHideToolbar=true`;
                    }

                    return (
                      <div key={check_List.FileRef} className="ppt-grid">
                        <a
                          className="cstm-links ms-3"
                          href={otherUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {check_List.FileLeafRef}
                        </a>
                      </div>
                    );
                  })}
                </div>
                <div
                  className="nav-link mega-menu-toggle"
                  style={{
                    borderRadius: "0.5rem",
                    background: "#932c35",
                    color: "#fff",
                  }}
                >
                  Other{" "}
                </div>

                <div className="mega-menu">
                  {other.map((other) => {
                    // Construct the URL with the necessary parameters for hiding SharePoint's UI
                    const fileExtension = other.FileLeafRef.split(".")
                      .pop()
                      .toLowerCase();
                    let otherUrl;
                    if (fileExtension === "pdf") {
                      otherUrl = `${SHAREPOINT_URI}/${encodeURIComponent(
                        other.FileRef
                      )}`;
                    } else {
                      otherUrl = `${SHAREPOINT_URI}/_layouts/15/WopiFrame.aspx?sourcedoc=${encodeURIComponent(
                        other.FileRef
                      )}&action=embedview&hidetoolbar=true&ui=embed&wdHideToolbar=true`;
                    }

                    return (
                      <div key={other.FileRef} className="ppt-grid">
                        <a
                          className="cstm-links ms-3"
                          href={otherUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {other.FileLeafRef}
                        </a>
                      </div>
                    );
                  })}
                </div>
                {/* </Nav> */}
              </div>
            </Nav>

            <Nav
              className=" my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <Nav.Link
                href="/newtraningRecord"
                style={{
                  borderRadius: "0.5rem",
                  color: "#fff",
                  background: "#932c35",
                }}
              >
                <i className="fa-solid fa-plus"></i> Create Training
              </Nav.Link>
            </Nav>
            <Nav
              className="my-2 my-lg-0"
              style={{ maxHeight: "" }}
              navbarScroll
            >
              <Nav.Link
                className="my-2 my-lg-0"
                href="/Logout"
                style={{
                  color: "#fff",
                  background: "#932c35",
                  borderRadius: "5px",
                }} // Add borderRadius here
                data-toggle="tooltip"
                data-placement="bottom"
                title={
                  currentUser
                    ? `${currentUser.Title} Want's To Logout!`
                    : "Want To Logout!"
                }
              >
                <i className="fa-solid fa-arrow-right-from-bracket fa-lg"></i>{" "}
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      </div>
    </>
  );
};

export default Nav_menu;
