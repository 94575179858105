import { useEffect } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js'; // Import CryptoJS

const Auth = () => {
    const navigate = useNavigate();

    const TENANT_ID = process.env.REACT_APP_AZURE_AUTHORITY;

    const config = {
        auth: {
            clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
            authority: `https://login.microsoftonline.com/${TENANT_ID}`,
            redirectUri: `${process.env.REACT_APP_AZURE_REDIRECT_URI}`,
        },
        cache: {
            cacheLocation: "sessionStorage",
        }
    };

    const msalInstance = new PublicClientApplication(config);

    const initializeMsal = async () => {
        await msalInstance.initialize();
    };

    const SCOPE_URI = process.env.REACT_APP_AZURE_SCOPES;


    const ENCRYPTION_KEY = process.env.REACT_APP_key
    const encryptToken = (token) => {
        return CryptoJS.AES.encrypt(token, ENCRYPTION_KEY).toString();
    };

    const handleLogin = async () => {
        try {
            await initializeMsal();
            const loginResponse = await msalInstance.loginPopup({
                scopes: [`${SCOPE_URI}`]
            });
            const accessToken = loginResponse.accessToken;

            const encryptedToken = encryptToken(accessToken);
            localStorage.setItem('authToken', encryptedToken);

       

            navigate('/dashboard');
        } catch (error) {
        }
    };

    useEffect(() => {
        handleLogin();
    }, []);

    return null;
};

export default Auth;












